<template>
  <div class="row pt-1" v-if="berechtigungen.m_bildung.read">
    <div class="col-lg-12">
      <div class="tile-categorie-frame">
        <div class="tile-categorie">
          <div class="header">
            <span>{{ $t("global.dashboard_education") }}</span>
          </div>
          <div class="body">
            <div class="row">
              <div
                :class="kachelCol"
                v-if="berechtigungen.b_bildung_bildungsgang.read"
              >
                <div class="tile-frame h-1" :class="kachelStyle">
                  <router-link :to="{ name: 'bildungsgang-liste' }">
                    <div class="tile bg-turkis inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-graduation-cap"
                          transform="left-2 shrink-2"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.educationcourse") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div
                :class="kachelCol"
                v-if="berechtigungen.b_bildung_ausbildungsuebersicht.read"
              >
                <div class="tile-frame h-1" :class="kachelStyle">
                  <router-link :to="{ name: 'AusbildungsUebersichtsListe' }">
                    <div class="tile bg-turkis inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-graduation-cap"
                          transform="left-2 shrink-2"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.trainingcourseoverview") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                :class="kachelCol"
                v-if="berechtigungen.b_bildung_klasse.read"
              >
                <div class="tile-frame h-1" :class="kachelStyle">
                  <router-link :to="{ name: 'klassen-liste' }">
                    <div class="tile bg-turkis inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-users-class"
                          transform="left-2 shrink-3"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.class") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <div :class="kachelCol" v-if="berechtigungen.b_bildung_fach.read">
                <div class="tile-frame h-1" :class="kachelStyle">
                  <router-link :to="{ name: 'faecher-liste' }">
                    <div class="tile bg-turkis inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-books"
                          transform="shrink-2"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.dashboard_subjects") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="row" v-if="berechtigungen.b_bildung_subtypen.read">
              <div
                :class="kachelKleinCol"
                v-if="berechtigungen.b_bildung_hf.read"
              >
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'HF' }">
                    <div class="tile bg-light-turkis inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-award"
                          transform="shrink-3"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.educationcourse-1") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                :class="kachelKleinCol"
                v-if="berechtigungen.b_bildung_nds.read"
              >
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'NDS' }">
                    <div class="tile bg-light-turkis inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-award"
                          transform="shrink-3"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.educationcourse-2") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                :class="kachelKleinCol"
                v-if="berechtigungen.b_bildung_hfp.read"
              >
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'HFP' }">
                    <div class="tile bg-light-turkis inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-award"
                          transform="shrink-3"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.educationcourse-3") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                :class="kachelKleinCol"
                v-if="berechtigungen.b_bildung_event.read"
              >
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'event-liste' }">
                    <div class="tile bg-light-turkis inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-award"
                          transform="shrink-3"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.dashboard_events") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                :class="kachelKleinCol"
                v-if="berechtigungen.b_bildung_infoanlass.read"
              >
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'infoanlass-liste' }">
                    <div class="tile bg-light-turkis inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-award"
                          transform="shrink-3"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.infoevent") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>

              <div
                :class="kachelKleinCol"
                v-if="berechtigungen.b_bildung_kurs.read"
              >
                <div class="tile-frame h-1" :class="kachelKleinStyle">
                  <router-link :to="{ name: 'kurs-liste' }">
                    <div class="tile bg-light-turkis inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-award"
                          transform="shrink-3"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.courses") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'

export default {
  name: "StartseiteBildung",
  components: {},
  mixins: [],
  props: {
    kachelStyle: { type: String },
    kachelCol: { type: String },
    kachelKleinStyle: { type: String },
    kachelKleinCol: { type: String },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>
