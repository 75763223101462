<template>
  <div class="row pt-1" v-if="berechtigungen.m_anmeldungen.read">
    <div class="col-lg-12">
      <div class="tile-categorie-frame">
        <div class="tile-categorie">
          <div class="header">
            <span>{{ $t("global.dashboard_registrations") }}</span>
          </div>
          <div class="body">
            <div class="row">
              <div
                v-if="
                  baseURL === 'https://skjv.bx-education.ch' ||
                  'https://skjv-dev.bx-education.ch' ||
                  'https://skjv-test.bx-education.ch'
                "
                :class="kachelCol"
              >
                <div class="tile-frame h-1" :class="kachelStyle">
                  <router-link :to="{ name: 'anmeldungen-liste-skjv' }">
                    <div class="tile bg-bright-orange inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-sign-in-alt"
                          transform="shrink-3"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.dashboard_registrations") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div v-else :class="kachelCol">
                <div class="tile-frame h-1" :class="kachelStyle">
                  <router-link :to="{ name: 'anmeldungen-liste' }">
                    <div class="tile bg-bright-orange inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-sign-in-alt"
                          transform="shrink-3"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.dashboard_registrations") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <div :class="kachelCol" v-if="berechtigungen.m_finanzen.read">
                <div class="tile-frame h-1" :class="kachelStyle">
                  <router-link :to="{ name: 'rechnung-anmeldungen-liste' }">
                    <div class="tile bg-light-orange inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon
                          icon="fa-duotone fa-sign-in-alt"
                          transform="shrink-3"
                        />
                      </div>
                      <div class="title">
                        {{ $t("global.invoicingapplications") }}
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              <!--
              <div class="col-xl-4 col-lg-12 col-md-12">
                <div class="tile-frame left-aligned h-1">
                  <router-link :to="{ name: 'lernzielvereinbarungs-liste' }">
                    <div class="tile bg-light-blue inverted">
                      <div class="icon-container fa-container">
                        <font-awesome-icon icon="fa-duotone fa-sign-in-alt" />
                      </div>
                      <div class="title">
                        Lernzielvereinbarungen
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'

export default {
  name: "StartseiteAnmeldungen",
  components: {},
  mixins: [],
  props: {
    kachelStyle: { type: String },
    kachelCol: { type: String },
    kachelKleinStyle: { type: String },
    kachelKleinCol: { type: String },
  },
  data() {
    return {
      baseURL: this.$CONST("CONFIG").BASEURL,
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>
